import { useEffect, useRef } from 'react';
import { object } from 'prop-types';
import { graphql } from 'gatsby';
import {
  Header, Opportunities, Benefits, Stories, Disclaimer,
} from '../../../components/pages/careers';
import * as styles from './index.module.scss';

function Careers({ data }) {
  const { careersSettings } = data;
  const {
    opportunities, benefits, stories, disclaimer,
  } = careersSettings || {};
  const isWindowDefined = typeof window !== 'undefined';
  const refValues = useRef(null);
  const refPositions = useRef(null);
  const executeScrollValues = () => refValues?.current?.scrollIntoView({ behavior: 'smooth' });
  const executeScrollPositions = () => refPositions?.current?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    if (isWindowDefined && window.location.hash.includes('open-positions')) {
      executeScrollPositions();
    }
  }, []);

  return (
    <div className={styles.pageContainer}>
      <Header data={careersSettings} scrollToValues={executeScrollValues} />
      <Opportunities myRef={refValues} data={opportunities} />
      <Benefits myRef={refValues} data={benefits} />
      <Stories data={stories} />
      <Disclaimer content={disclaimer?.disclaimer} />
    </div>
  );
}
export const query = graphql`
  {
    careersSettings: contentfulPageCareersSettings(id: {eq: "cd0a78c4-a261-588b-8db8-61c00b84adb7"}) {
      id
      title {
        raw
      }
      buttonText
      media {
        description
        title
        file {
          url
        }
        gatsbyImage(width: 450)
      }
      benefits {
        id
        title
        image {
          description
          gatsbyImage(width: 260)
        }
        description {
          description
        }
      }
      stories {
        id
        title
        video {
          title
          file {
            url
          }
        }
        description {
          description
        }
        attorney {
          title
          uri
          slug
        }
      }
      opportunities {
        id
        uri
        employmentType
        salaryArrangement
        shortDescription {
          shortDescription
        }
        location {
          locationTitle
          slug
          addressRegion
        }
        department
        minimumSalary
        maximumSalary
        title
      }
      disclaimer {
        disclaimer
      }
      seo {
        pageTitle
        metaDescription
      }
    }
  }
`;
Careers.propTypes = {
  data: object,
};
Careers.defaultProps = {
  data: {},
};
export default Careers;
export { Head } from '../../../components/seo/seo';
